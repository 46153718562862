// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-11013(914d7df97d)-C12/12/2024-09:37:06-B12/12/2024-09:41:06' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-11013(914d7df97d)-C12/12/2024-09:37:06-B12/12/2024-09:41:06",
  branch: "master",
  latestTag: "6.1",
  revCount: "11013",
  shortHash: "914d7df97d",
  longHash: "914d7df97dd7a25a7bd5597edec45a22e274c5c8",
  dateCommit: "12/12/2024-09:37:06",
  dateBuild: "12/12/2024-09:41:06",
  buildType: "Ansible",
  } ;
